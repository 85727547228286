import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

function pageInProgress () {
  return (
    <Layout>

    <section className="blog">
      <div className="container">
        <div className="row">

            <div className="col-xl-6 offset-xl-3 col-lg-12 col-md-12 col-12 text-center">
                <h1>This page is still in progress</h1>
            </div>
            
        </div>
      </div>
    </section>

  </Layout>

  )
}

export const Head = () => <Seo title="iamcore | Page is in progress" noindex="yes"/>

export default pageInProgress
